import logo from "../img/assembly.jpg";

import About from "../pages/About";
import Facilities from "./Facilities";
import Contact from "./Contact";
import Footer from "../Templates/Footer";

export default function Home() {
    return (
        <>
            <div className="home" style={{ marginTop: "2̧̧%", textAlign: "center" }}>
                <img src={logo} style={{ width: "100%" }} />
            </div>
            <About/>
            <Facilities/>
            <Contact/>
            <Footer/>
        </>
    )
}