import "../css/contact.css";

export default function Contact() {
    return (
        
<div className="row" id="contact">
            <div className="col-sm-6 p-5">
                <h1 style={{fontSize:"30px"}}>Contact US</h1><br></br>
                <p><i className="fa fa-map-marker"></i> Nagri Road, Padampur, Lodhma khunti, Ranchi, Jharkhand</p>
                <p><i className="fa fa-mobile"></i> 7004491211</p>
                <p><i className="fa fa-whatsapp" style={{ color: "green" }}></i> 9102722391</p>
                <p><i className="fa fa-envelope"></i> vijay@worldedupublicschool.in</p>
                {/* <p>Write Your Quries, We will get back to you soon</p>
                    <form action="" target="_blank">
                    <p><input className="w3-input w3-padding-16" type="text" placeholder="Name" required name="Name"></p>
                    <p><textarea className="w3-input w3-padding-16" type="text" placeholder="Write your query here" required name="Message"></textarea></p>
                    <p><button className="w3-button w3-light-grey w3-section" type="submit">SEND MESSAGE</button></p>
                    </form>  */}
            </div>
            </div>

    )
}