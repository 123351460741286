import "../css/login.css";
import $ from "jquery";
import {useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Login() {

    const [input, setInput] = useState({});
    const [loginData, setData] = useState({});

    const navigate = useNavigate();
    //const hostName = "http://localhost/weps"; 
    const hostName = "https://worldedupublicschool.in/"; 

    const showHide = (action) => {
        (action === "login") ? (() => {
            $("#login").show();
            $("#adminLogin").hide();
        })() : (() => {
            $("#login").hide();
            $("#adminLogin").show();
        })();
    }

    const handleAdminLogin = (event) =>{
        event.preventDefault();
        console.log(loginData);
        axios.post(hostName+"api/adminLogin.php", loginData).then((resp)=>{
            console.log(resp);
            $("#navItmes").hide();
            $("#navItmes_mob").hide();
            $("#adminNavItmes").show();
            $("#adminNavItmes_mob").show();
            $("#loggedInUser").prepend((resp.data.data.name).toUpperCase());
            $("#loggedInUser_mob").prepend((resp.data.data.name).toUpperCase());

            localStorage.setItem('user', JSON.stringify(resp.data.data));
            navigate("/");
            window.location.reload();
        }).catch((resp)=>{
            //alert("Invalid Credentials!!");
            $("#alert_admin").html("Invalid Credentials!!").css("color","red");
        });
    }
    const handleStudentLogin = (event) =>{
        event.preventDefault();
        axios.post(hostName+"api/studentLogin.php", loginData).then((resp)=>{
            console.log(resp);
            alert(resp.data);
        }).catch((resp)=>{
            alert("Invalid Credentials!!");
        });
    }
    const handleLoginChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData(values =>({...values, [name]:value}));
    }

    return (
        <>
            <div className="row visible" id="adminLogin" style={{ display: "none" }}>
                <div className="col-sm-12">
                    <h3 className="text-center">Admin Login</h3>
                    <form className="loginForm" onSubmit={handleAdminLogin}>
                        <input type="text" placeholder="Enter School Email Id" name="id" required onChange={handleLoginChange}></input>
                        <input type="password" placeholder="Enter Password" name="pass" required onChange={handleLoginChange}></input>
                        <button type="submit">Login</button>
                        <p id="alert_admin" style={{textAlign:"center", marginTop:"10px",fontSize:"14px"}}></p>
                    </form>
                </div>
                <p style={{ textAlign: "center", fontSize: "14px", marginTop: "5%" }}>Student ? <a style={{ padding: "3px", color: "blue" }} onClick={() => { showHide("login") }}>Click here</a> for Student Login</p>
            </div>

            <div className="row visible" id="login">
                <div className="col-sm-12">
                    <h3 className="text-center">Student Login</h3>
                    <form className="loginForm" onSubmit={handleStudentLogin}>
                        <input type="text" placeholder="Login Id (combination of std. & Roll no.)" name="id" required onChange={handleLoginChange}></input>
                        <input type="password" placeholder="Enter Password" name="pass" required onChange={handleLoginChange}></input>
                        <button type="submit">Login</button>
                    </form>
                </div>
                <p style={{ textAlign: "center", fontSize: "14px", marginTop: "5%" }}>Admin ? <a style={{ padding: "3px", color: "blue" }} onClick={() => { showHide("admin") }}>Click here</a>for Admin Login</p>
            </div>

        </>
    )
}