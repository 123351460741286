import school from "../img/school_long.jpg";
export default function About(){
    return(

          <div className="row " id="about" >
            <div className="col-sm-6 p-5 d-none d-sm-block">
             <img src={school} alt="Table Setting" width="100%" height=""></img>
            </div>
        
            <div className="col-sm-6 p-5">
              <h1 className="text-center" style={{fontSize:"30px"}}>ABOUT WEPS</h1><br></br>
              <h5 className="text-center mt-5" style={{fontSize:"24px"}}>Estd. 2015</h5>
              <p className="text-justify" style={{lineHeight:"30px", fontSize:"18px"}}>World Education Public School (WEPS) is an institution where students embark on a journey of learning and personal growth. It serves as a foundation for acquiring knowledge, developing essential skills, and fostering social interactions. At its core, school provides a structured environment where students engage with a diverse range of subjects, from mathematics and science to literature and the arts.
        
                Beyond academics, schools play a pivotal role in shaping students' character and values. They cultivate critical thinking, problem-solving abilities, and teamwork skills through various activities such as group projects, debates, and extracurriculars. Moreover, schools often serve as hubs for community engagement, hosting events, and initiatives that involve students, teachers, parents, and local organizations.
                
                In addition to formal education, schools offer a platform for students to explore their interests and talents. Whether through sports teams, music ensembles, or academic clubs, students have the opportunity to pursue their passions and develop a sense of identity outside the classroom.
                
                Furthermore, schools are not just places of learning but also environments that prioritize safety, inclusion, and support for every student. They strive to create an atmosphere where individuals feel valued, respected, and empowered to reach their full potential.
                
                In essence, school is more than just a building with classrooms—it's a dynamic community dedicated to nurturing the minds and hearts of the next generation.</p>
              <p className="w3-large w3-text-grey w3-hide-medium"></p>
            </div>
          </div>
    )
}