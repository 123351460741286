import "../css/login.css";
import $ from "jquery";
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function Registration() {

    const [input, setInput] = useState({});
    const navigates = useNavigate();
    //const hostName = "http://localhost/weps"; 
    const hostName = "https://worldedupublicschool.in/"; 

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(input);
        let messg = "";
        let color = "red";
        if (($("input[name='password']").val()).length < 5 || ($("input[name='password']").val()).length > 10) {
            messg = "Password should be between 5 to 10 characters!!";
        } else if ($("input[name='password']").val() !== $("input[name='conf_password']").val()) {
            messg = "Password Missmatched!!";
        } else {
            localStorage.user && (() => {
                const adminData = JSON.parse(localStorage.user);
                input.admin = adminData.email;
            })();

            axios.post(hostName+"api/index.php", input).then((resp) => {
                console.log(resp);
                //alert(resp.data);
                messg = resp.data;
                $("#alert").html(messg).css("color","green");
                return;
            }).catch((resp) => {
                //alert(resp.response.data + " Please Login With Admin Credentials!!");
                messg=resp.response.data + " Please Login With Admin Credentials!!";
                $("#alert").html(messg).css("color",color);
            });
        }
        $("#alert").html(messg).css("color",color);
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInput(values => ({ ...values, [name]: value }));
    }

    return (
        <>
        {localStorage.user &&
            <div className="row" id="signup">
                <div className="col-sm-12">
                    <h3 className="text-center">Register</h3>
                    <form className="RegForm" onSubmit={handleSubmit}>
                        <input type="hidden" name="add_student" value="1"></input>
                        <span className="col-sm-6" ><label className="lebel">Standard</label>
                            <select className="Select-std" aria-label="Default select example" name="std" required onChange={handleChange}>
                                <option selected value="">Select Std.</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                                <option value="5">Five</option>
                                <option value="6">Six</option>
                                <option value="7">Seven</option>
                                <option value="8">Eight</option>
                            </select>
                        </span>
                        <span className="col-sm-6"><label className="lebel">Roll No.</label><input type="number" placeholder="Enter Roll No." required name="roll_no" onChange={handleChange}></input></span>
                        <span className="col-sm-6"><label className="lebel">Full Name</label><input type="text" name="name" placeholder="Enter Full Name" required onChange={handleChange}></input></span>

                        <span className="col-sm-6" ><label className="lebel">DOB</label><input type="date" placeholder="Enter DOB" required name="dob" onChange={handleChange}></input></span>

                        <span className="col-sm-6"><label className="lebel">Father's Name</label><input type="text" placeholder="Enter Father's Name" required name="fathers_name" onChange={handleChange}></input></span>
                        <span className="col-sm-6"><label className="lebel">Mother's Name</label><input type="text" placeholder="Enter Mother's Name" required name="mothers_name" onChange={handleChange}></input></span>

                        <span className="col-sm-6"><label className="lebel">Mobile No</label><input type="tel" placeholder="Enter Mobile No." required name="mobile_no" onChange={handleChange}></input></span>
                        <span className="col-sm-6"><label className="lebel">Email Id</label><input type="email" placeholder="Enter Email Id (optional)" name="email_id" onChange={handleChange}></input></span>
                        <span className="col-sm-6"><label className="lebel">Password</label><input type="password" placeholder="Enter Password" required name="password" onChange={handleChange}></input></span>
                        <span className="col-sm-6"><label className="lebel">Confirm Password</label><input type="text" placeholder="Confirm Password" required name="conf_password" onChange={handleChange}></input></span>                      
                        <button type="submit">Register Now</button>
                    </form>
                    <p id="alert" style={{textAlign:"center", marginTop:"10px",fontSize:"14px"}}></p>
                </div>
                {/* <p style={{ textAlign: "center", fontSize: "14px", marginTop: "5%" }}>Already Registered ? <a style={{ padding: "3px", color: "blue" }} onClick={() => { showHide("login") }}>Click here</a>to Login</p> */}
            </div>
            
            }
        </>
    )
}