import { BrowserRouter, Route, Routes, Link, HashRouter, useNavigate, MemoryRouter } from "react-router-dom";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBars } from "@fortawesome/free-solid-svg-icons";

import "../css/header.css";

import Home from "../pages/Home";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Facilities from "../pages/Facilities";
import Login from "../pages/Login.js";

import $ from 'jquery';

import logo from "../img/weps_logo.jpg";
import Registration from "../pages/Registration.js";



export default function Header() {

    const toggle = () => {
        ($("#toggle").hasClass("tgl_hidden")) ? (() => {
            $("#toggle").show();
            $("#toggle").removeClass("tgl_hidden");
            $("#toggle").addClass("tgl_visible");
        })() : (() => {
            $("#toggle").hide();
            $("#toggle").addClass("tgl_hidden");
            $("#toggle").removeClass("tgl_visible");
        })();

    }

    const Logout = (event) => {
        event.preventDefault();
        localStorage.clear();
        window.location.reload();
        //const navigate = useNavigate();
        //navigate('/');
    }
    let adminname = "";
    localStorage.user && (() => {
        const adminData = JSON.parse(localStorage.user);
        adminname = adminData.name;
    })();

    return (
        <navbar>
            <HashRouter>
                <nav className="myNav">
                    <Link to="/"><img src={logo} width="60" height="55"></img>
                        <span className="schoolName">World Education
                            <span className="publicSchool">Public School</span></span>
                    </Link>
                    {localStorage.user ?

                        <span className="navItmes" id="adminNavItmes">
                            <Link to="https://sg2plzcpnl504599.prod.sin2.secureserver.net:2096/cpsess6877893046/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX" style={{ marginRight: "15px", letterSpacing: "4px" }} target="_blank">EMAIL</Link>
                            <Link to="/Register" style={{ marginRight: "15px", letterSpacing: "4px" }}>ADD STUDENTS</Link>
                            <Link style={{ marginRight: "15px", letterSpacing: "4px" }} id="loggedInUser">{adminname} <i class="fa fa-sign-out" onClick={Logout} title="logout"></i></Link>
                        </span>
                        :
                        <span className="navItmes" id="navItmes">
                            <Link to="/Facilities" style={{ marginRight: "15px", letterSpacing: "4px" }}>FACILITIES</Link>
                            <Link to="/About" style={{ marginRight: "15px", letterSpacing: "4px" }}>ABOUT</Link>
                            <Link to="/Contact" style={{ marginRight: "15px", letterSpacing: "4px" }}>CONTACT</Link>
                            <Link to="/Login" style={{ marginRight: "15px", letterSpacing: "4px" }}>LOGIN</Link>
                        </span>

                    }
                </nav>
                <nav className="myNav-mob">
                    <Link to="/"><img src={logo} width="50" height="45"></img>
                        <span className="schoolName">World Education
                            <span className="publicSchool">Public School</span></span>
                    </Link>
                    {/* <FontAwesomeIcon icon={faBars} style={{ float: "right", paddingTop: "12px" , color:"#de6c5a"}} onClick={toggle} /> */}
                    <li className="fa fa-bars" style={{ float: "right", paddingTop: "12px", color: "#de6c5a" }} onClick={toggle}></li>
                </nav>
                <div id="toggle" className="tgl_hidden" style={{ display: "none" }}>
                    {localStorage.user ?
                        <ul id="adminNavItmes_mob">
                            <li><Link to="https://sg2plzcpnl504599.prod.sin2.secureserver.net:2096/cpsess6877893046/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX" target="_blank" style={{ letterSpacing: "4px" }} onClick={toggle}>EMAIL</Link></li>
                            <li><Link to="/Register" style={{ letterSpacing: "4px" }} onClick={toggle}>ADD STUDENTS</Link></li>
                            <li><Link style={{ letterSpacing: "4px" }} onClick={toggle} id="loggedInUser_mob">{adminname} <i class="fa fa-sign-out" onClick={Logout} title="logout"></i> </Link></li>
                        </ul>
                        :
                        <ul id="navItmes_mob">
                            <li><Link to="/About" style={{ letterSpacing: "4px" }} onClick={toggle}>ABOUT</Link></li>
                            <li><Link to="/Facilities" style={{ letterSpacing: "4px" }} onClick={toggle}>FACILITIES</Link></li>
                            <li><Link to="/Contact" style={{ letterSpacing: "4px" }} onClick={toggle}>CONTACT</Link></li>
                            <li><Link to="/Login" style={{ letterSpacing: "4px" }} onClick={toggle}>LOGIN</Link></li>
                        </ul>
                    }
                </div>

                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/About" element={<About />}></Route>
                    <Route path="/Contact" element={<Contact />}></Route>
                    <Route path="/Facilities" element={<Facilities />}></Route>
                    <Route path="/Login" element={<Login />}></Route>
                    <Route path="/Register" element={<Registration />}></Route>
                </Routes>
            </HashRouter>

        </navbar>
    )


}