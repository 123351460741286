import classroom from "../img/classroom.jpg";
import playground from "../img/playground.jpg";
export default function Facilities(){
    return(

        <div className="row p-5" id="menu">
          <div className="col-sm-6">
            <h1 className="text-center mb-5" style={{fontSize:"30px"}}>OUR FACILITIES</h1><br></br>
            <h4>Smart Clasess</h4>
            <p className="w3-text-grey">We are providing smart class Facilities to our students to make them smart in digital world.</p><br></br>
          
            <h4>BUS Facilities</h4>
            <p className="w3-text-grey">we are having bus Facilities which cover entire city.</p><br></br>
          
            <h4>Play Zone</h4>
            <p className="w3-text-grey">We have big play ground/zone equiped with all gaming Facilities and Sport Teacher </p><br></br>
          
            <h4>Music Classes</h4>
            <p className="w3-text-grey">We are offering music class in our campus for interested studens.</p><br></br>
          
            <h4>Doubt Classes</h4>
            <p className="w3-text-grey">We are offering extra hours of doubt classe.</p>    
          </div>
          
          <div className="col-sm-6 w3-padding-large">
            <img src={classroom} className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width:"100%"}}></img>
            <img src={playground} className="w3-round w3-image w3-opacity-min" alt="Menu" style={{width:"100%"}}></img>
            
          </div>
        </div>
    )
}